import React from 'react'
import styled from 'styled-components'

// Components
import AnchorPrimary from 'components/elements/Anchors/AnchorPrimary'
import ParseContent from 'components/shared/ParseContent'

// Images
import Pointer from 'img/location.inline.svg'

// Interface
import { LatestPostsProps } from 'components/flex/Posts/Latest/Shell'

const Wrapper = styled.div`
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
`
const Label = styled.div`
  background: linear-gradient(34.64deg, #63b9e9 18.71%, #ee7202 84.69%);
  color: ${({ theme }) => theme.color.light};
  font-family: ${({ theme }) => theme.font.family.secondary};
  min-height: 135px;

  & span {
    @media (min-width: 992px) {
      font-size: 18px;
    }
  }

  & h2 {
    @media (min-width: 992px) {
      font-size: 25px;
    }
  }

  &:before {
    content: '';
    position: absolute;
    right: 100%;
    top: 0px;
    bottom: 0px;
    width: 220px;
    background-color: ${({ theme }) => theme.color.primary};
  }
`

const Content = styled(ParseContent)`
  & p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    color: ${({ theme }) => theme.color.dark};
  }
`

const PostTertiary: React.FC<LatestPostsProps> = ({ post, fields }) => (
  <div className="col-lg-6">
    <a href={post.uri}>
      <Wrapper className="p-4 p-sm-5 mb-5 overflow-hidden">
        <div className="row">
          <div className="col-lg-11">
            <Label className="position-relative p-3">
              <div className="d-flex align-items-center">
                <Pointer />
                <span className="ms-3">{post.recap.location}</span>
              </div>
              <h2 className="mt-2">{post.title}</h2>
            </Label>
          </div>
        </div>

        <div className="row align-items-center mt-4">
          <div className="col-md-12">
            <Content content={post.recap.excerpt} className="" />
          </div>
          <div className="col-md-12 d-flex justify-content-start mt-4">
            <AnchorPrimary type="contrast" to={post.uri}>
              {fields.readmoretext}
            </AnchorPrimary>
          </div>
        </div>
      </Wrapper>
    </a>
  </div>
)

export default PostTertiary
