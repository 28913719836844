import React from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import BlogBink, { BlogBinkPosts } from '@ubo/blog-bink'

// Layout
import GridPrimary from 'components/flex/Posts/Layout/GridPrimary'

// Interface
import { HighlightedPostsProps } from 'components/flex/Posts/Highlighted/Shell'

const Section = styled.section`
  background-color: #f8f8f8;
`

const HighlightedProjects: React.FC<HighlightedPostsProps> = ({ fields }) => {
  const {
    allWpProject,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.highlightedProjectsQueryQuery>(graphql`
    query highlightedProjectsQuery {
      allWpProject(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalProjectFragment
          }
        }
      }
    }
  `)

  const posts: any = allWpProject.edges

  return (
    <Section className="mb-5 py-5">
      <BlogBink
        posts={posts as BlogBinkPosts}
        id="highlighted-posts"
        limit={Infinity}
        showIds={fields?.projectrelation?.map(
          ({ databaseId }: any) => databaseId
        )}
      >
        <div className="container">
          {/* @ts-ignore */}
          <GridPrimary posts={posts} fields={fields} />
        </div>
      </BlogBink>
    </Section>
  )
}

export default HighlightedProjects
